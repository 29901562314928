$enable-gradients: false;
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 3
    ),
    6: (
      $spacer * 5
    )
  ),
  $spacers
);

:root {
  --bs-border-radius: 0.375rem;
  --bs-border-radius-pill: 50rem;
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/helpers';

$utilities: map-merge(
  $utilities,
  (
    'min-vh':
      map-merge(
        map-get($utilities, 'min-viewport-height'),
        (
          responsive: true,
          property: min-height,
          values: (
            20: 20vh,
            40: 40vh,
            60: 60vh,
            80: 80vh,
            90: 90vh,
            100: 100vh
          )
        )
      ),
    'vw':
      map-merge(
        map-get($utilities, 'viewport-width'),
        (
          responsive: true,
          property: width,
          values: (
            20: 20vw,
            30: 30vw,
            40: 40vw,
            50: 50vw,
            60: 60vw,
            70: 70vw,
            80: 80vw,
            90: 90vw,
            100: 100vw
          )
        )
      ),
    'mvw': (
      responsive: true,
      property: max-width,
      class: mvw,
      values: (
        100: 100vw
      )
    ),
    'mvh': (
      responsive: true,
      property: max-height,
      class: mvh,
      values: (
        100: 100vh,
        40: 40vh,
        50: 50vh
      )
    ),
    'bg-opacity':
      map-merge(
        map-get($utilities, 'bg-opacity'),
        (
          values: (
            65: 0.65
          )
        )
      ),
    'border-spacing': (
      property: border-spacing,
      values: (
        1: 1px
      )
    ),
    'border-collapse': (
      property: border-collapse,
      values: (
        separate: separate,
        collapse: collapse
      )
    ),
    'fade': (
      property: background,
      class: fade,
      values: (
        down-dark: linear-gradient(#212529ff, #21252900),
        up-dark: linear-gradient(#21252900, #212529ff),
        down-dark-75: linear-gradient(#212529bf, #21252900),
        up-dark-75: linear-gradient(#21252900, #212529bf),
        down-dark-65: linear-gradient(#212529a6, #21252900),
        up-dark-65: linear-gradient(#21252900, #212529a6),
        down-black: linear-gradient(#000000ff, #00000000),
        up-black: linear-gradient(#00000000, #000000ff),
        down-white: linear-gradient(#ffffffff, #ffffff00),
        up-white: linear-gradient(#ffffff00, #ffffffff)
      )
    ),
    'cursor': (
      property: cursor,
      class: cursor,
      responsive: true,
      values: auto pointer grab
    ),
    'vwh': (
      responsive: true,
      class: vwh,
      property: height,
      values: (
        20: 20vw,
        25: 25vw,
        40: 40vw,
        50: 50vw,
        60: 60vw,
        75: 75vw,
        80: 80vw,
        90: 90vw,
        100: 100vw
      )
    ),
    'color':
      map-merge(
        map-get($utilities, 'color'),
        (
          responsive: true,
          property: color,
          state: hover,
          class: text,
          values: (
            white: $white,
            gray-100: $gray-100,
            gray-200: $gray-200,
            gray-300: $gray-300,
            gray-400: $gray-400,
            gray-500: $gray-500,
            gray-600: $gray-600,
            gray-700: $gray-700,
            gray-800: $gray-800,
            gray-900: $gray-900,
            black: $black,
            primary: $primary
          )
        )
      ),
    'bg':
      map-merge(
        map-get($utilities, 'background-color'),
        (
          responsive: true,
          property: background-color,
          state: hover,
          values: (
            null: transparent,
            primary: $primary,
            secondary: $secondary,
            success: $success,
            info: $info,
            warning: $warning,
            danger: $danger,
            light: $light,
            gray-100: $gray-100,
            gray-200: $gray-200,
            gray-300: $gray-300,
            gray-400: $gray-400,
            gray-500: $gray-500,
            gray-600: $gray-600,
            gray-700: $gray-700,
            gray-800: $gray-800,
            gray-900: $gray-900,
            white: $white,
            black: $black
          )
        )
      ),
    'border':
      map-merge(
        map-get($utilities, 'border'),
        (
          responsive: true,
          property: border,
          values: (
            null: $border-width solid $border-color,
            0: 0,
            gray-100: $border-width solid $gray-100,
            gray-200: $border-width solid $gray-200,
            gray-300: $border-width solid $gray-300,
            gray-400: $border-width solid $gray-400,
            gray-500: $border-width solid $gray-500,
            gray-600: $border-width solid $gray-600,
            gray-700: $border-width solid $gray-700,
            gray-800: $border-width solid $gray-800,
            gray-900: $border-width solid $gray-900,
            white: $border-width solid $white,
            black: $border-width solid $black
          )
        )
      ),
    'border-start':
      map-merge(
        map-get($utilities, 'border-start'),
        (
          responsive: true,
          property: border-left,
          values: (
            null: $border-width solid $border-color,
            0: 0,
            gray-100: $border-width solid $gray-100,
            gray-200: $border-width solid $gray-200,
            gray-300: $border-width solid $gray-300,
            gray-400: $border-width solid $gray-400,
            gray-500: $border-width solid $gray-500,
            gray-600: $border-width solid $gray-600,
            gray-700: $border-width solid $gray-700,
            gray-800: $border-width solid $gray-800,
            gray-900: $border-width solid $gray-900,
            dark: $border-width solid $dark,
            white: $border-width solid $white,
            black: $border-width solid $black,
            primary: $border-width solid $primary
          )
        )
      ),
    'border-top':
      map-merge(
        map-get($utilities, 'border-top'),
        (
          responsive: true,
          property: border-top,
          values: (
            null: $border-width solid $border-color,
            0: 0,
            gray-100: $border-width solid $gray-100,
            gray-200: $border-width solid $gray-200,
            gray-300: $border-width solid $gray-300,
            gray-400: $border-width solid $gray-400,
            gray-500: $border-width solid $gray-500,
            gray-600: $border-width solid $gray-600,
            gray-700: $border-width solid $gray-700,
            gray-800: $border-width solid $gray-800,
            gray-900: $border-width solid $gray-900,
            dark: $border-width solid $dark,
            white: $border-width solid $white,
            black: $border-width solid $black,
            primary: $border-width solid $primary
          )
        )
      ),
    'border-end':
      map-merge(
        map-get($utilities, 'border-end'),
        (
          responsive: true,
          property: border-right,
          values: (
            null: $border-width solid $border-color,
            0: 0,
            gray-100: $border-width solid $gray-100,
            gray-200: $border-width solid $gray-200,
            gray-300: $border-width solid $gray-300,
            gray-400: $border-width solid $gray-400,
            gray-500: $border-width solid $gray-500,
            gray-600: $border-width solid $gray-600,
            gray-700: $border-width solid $gray-700,
            gray-800: $border-width solid $gray-800,
            gray-900: $border-width solid $gray-900,
            dark: $border-width solid $dark,
            white: $border-width solid $white,
            black: $border-width solid $black,
            primary: $border-width solid $primary
          )
        )
      ),
    'border-bottom':
      map-merge(
        map-get($utilities, 'border-bottom'),
        (
          responsive: true,
          property: border-bottom,
          values: (
            null: $border-width solid $border-color,
            0: 0,
            gray-100: $border-width solid $gray-100,
            gray-200: $border-width solid $gray-200,
            gray-300: $border-width solid $gray-300,
            gray-400: $border-width solid $gray-400,
            gray-500: $border-width solid $gray-500,
            gray-600: $border-width solid $gray-600,
            gray-700: $border-width solid $gray-700,
            gray-800: $border-width solid $gray-800,
            gray-900: $border-width solid $gray-900,
            dark: $border-width solid $dark,
            white: $border-width solid $white,
            black: $border-width solid $black,
            primary: $border-width solid $primary
          )
        )
      ),
    'min-width': (
      responsive: true,
      property: min-width,
      values: (
        1: 1rem,
        2: 2rem,
        3: 3rem,
        4: 4rem,
        5: 5rem
      )
    ),
    'height':
      map-merge(
        map-get($utilities, 'height'),
        (
          responsive: true,
          property: height,
          class: h,
          values: (
            1: 1rem,
            2: 2rem,
            3: 3rem,
            4: 4rem,
            5: 5rem
          )
        )
      )
  )
);

@import '~bootstrap/scss/utilities/api';
// @import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@mixin proportional($percentage: 56.25%) {
  &::before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: $percentage;
    transition: padding 0.3s;
  }
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin open-sans {
  font-family: 'Open Sans', Roboto, Hevetica, Arial, sans-serif;
}

@mixin black-grad-down() {
  background: linear-gradient(#00000000, #000000ff);
}

@mixin black-grad-up() {
  background: linear-gradient(#000000ff, #00000000);
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  background: #f0f0f0;
  height: 100%;
}

div#root {
  position: relative;
  min-height: 100%;
}

a.inline-link {
  color: #212529;
  border-bottom: 1px dotted #212529;
  text-decoration: none;
  &:hover {
    border-bottom: 2px solid #212529;
  }
}

.text-small {
  font-size: 0.8rem;
}

.text-smallest {
  font-size: 0.7rem;
}

.text-vertical {
  writing-mode: vertical-rl;
}

.font-open-sans {
  @include open-sans();
}

.text-align-justify {
  text-align: justify;
  text-justify: inter-word;
}

.card-header {
  font-size: 1.2em;
  &[variant='link'] {
    cursor: pointer;
  }
  .card-header__icon {
    width: 2em;
  }
}

.page-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.form-text {
  margin-top: 0;
  margin-bottom: 0.25rem;
}

.svg-icon {
  height: 1em;
  &.svg-icon--logo {
    height: 1.5em;
  }
}
.text-white {
  .svg-icon,
  &.svg-icon {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(152deg) brightness(103%) contrast(103%);
  }
}
.text-dark {
  .svg-icon,
  &.svg-icon {
    filter: invert(20%) sepia(19%) saturate(320%) hue-rotate(169deg) brightness(98%) contrast(92%);
  }
}
.text-primary {
  .svg-icon,
  &.svg-icon {
    filter: invert(29%) sepia(93%) saturate(1617%) hue-rotate(197deg) brightness(103%) contrast(111%);
  }
}
.text-secondary {
  .svg-icon,
  &.svg-icon {
    filter: invert(49%) sepia(7%) saturate(568%) hue-rotate(166deg) brightness(91%) contrast(89%);
  }
}
.text-danger {
  .svg-icon,
  &.svg-icon {
    filter: invert(24%) sepia(67%) saturate(3673%) hue-rotate(341deg) brightness(94%) contrast(82%);
  }
}
.text-success {
  .svg-icon,
  &.svg-icon {
    filter: invert(49%) sepia(13%) saturate(2340%) hue-rotate(81deg) brightness(103%) contrast(96%);
  }
}
.text-warning {
  .svg-icon,
  &.svg-icon {
    filter: invert(67%) sepia(94%) saturate(461%) hue-rotate(348deg) brightness(102%) contrast(109%);
  }
}
.text-info {
  .svg-icon,
  &.svg-icon {
    filter: invert(51%) sepia(73%) saturate(504%) hue-rotate(140deg) brightness(89%) contrast(92%);
  }
}
.text-muted {
  .svg-icon,
  &.svg-icon {
    filter: invert(47%) sepia(3%) saturate(1446%) hue-rotate(167deg) brightness(95%) contrast(87%);
  }
}
.text-light {
  .svg-icon,
  &.svg-icon {
    filter: invert(98%) sepia(0%) saturate(6248%) hue-rotate(96deg) brightness(111%) contrast(97%);
  }
}

.badge-large {
  font-size: 1em;
}

.file-input {
  &.file-input--icon {
    input[type='file'] {
      display: none;
    }
  }
}

.menu {
  .menu__logo {
    height: 45px;
    max-width: 100%;
  }
  .nav-link {
    padding: 0.6rem 0;
  }
}

.breadcrumb {
  background: transparent;
  padding: 0;
  margin: 0;
  color: #6c757d;
  a {
    color: #6c757d;
    &[aria-current='page'],
    &:hover {
      color: #212529;
    }
  }
}

.player {
  height: 100vh;
  max-height: 100vh;
  background-color: #000;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-position: center center;
  background-size: cover;
  .player-video {
    @include proportional();
    position: relative;
    background: #000;
    max-height: calc(100vh - 79px);
    iframe {
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }
  }
  .player-footer {
    .footer__controls {
      font-size: 1.4em;
      .footer_fullscreen-btn {
        padding-top: 0.12em;
      }
      .comment-button--hide {
        .svg-icon {
          vertical-align: -10%;
        }
      }
      .footer__expiry {
        span {
          font-size: 0.8rem;
        }
      }
    }
    .footer__cta {
      font-size: 0.8em;
      .btn {
        font-size: 1em;
      }
    }
  }
  .player-comments {
    flex: 1;
    .player-comments__popout {
      margin-top: 0.35em;
    }
  }
  .player__button {
    font-size: 2em;
  }
}

#Feed {
  .card-img-top {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 15em;
    position: relative;
    h4 {
      background: linear-gradient(#00000000, #000000ff);
      font-weight: normal;
      position: absolute;
      bottom: 0;
    }
  }
}

.feed-header__row {
  display: initial;
  .feed-header {
    display: initial;
    .feed-header__controls {
      top: 0;
      z-index: 900;
      .feed-header__controls__chat {
        font-size: 1.2em;
      }
    }
  }
}

.preview {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  &.fullscreen {
    height: 100vh !important;
  }
  .heading {
    background: linear-gradient(#000000ff, #00000000);
    h1 {
      font-size: 2rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }
  .preview__description {
    max-height: 45vh;
    overflow: auto;
  }
  .preview__footer {
    background: linear-gradient(#00000000, #000000ff);
    img {
      max-height: 25vh;
      max-width: 100%;
    }
    .preview__comments-btn {
      font-size: 2em;
      white-space: nowrap;
    }
  }
  .preview__subtitle {
    font-size: 0.9em;
    font-style: italic;
  }
}

.banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  .banner__inner {
    z-index: 10;
  }
  .banner__top {
    position: relative;
    width: 100%;
    @include proportional(30%);
    .banner__controls {
      font-size: 0.9em;
      background: linear-gradient(#000000cc, #00000000);
      .feed-card__edit {
        cursor: pointer;
      }
    }
  }
  .banner__title {
    background: linear-gradient(#00000000, #000000cc);
    h1 {
      font-weight: normal;
    }
  }
  .banner__bottom {
    .banner__description {
      background: #000000cc;
      p {
        line-height: 1.5rem;
      }
    }
  }
  .banner__logo {
    max-height: 20vh;
  }
  .banner__link {
    &.banner__link-entity {
      font-size: 0.9em;
      font-style: italic;
    }
  }
  &.banner--user {
    .avatar {
      width: 128px;
      height: 128px;
    }
  }
  &.banner--payment {
    @include proportional(20%);
  }
  .carousel {
    z-index: 5;
    .carousel-inner {
      height: 100%;
      .carousel-item {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }
    }
  }
}

.time-display {
  font-weight: normal;
  font-size: 0.7em;
  &.time-display--emphasised {
    font-size: 1.3em;
    font-weight: bold;
  }
}

textarea {
  &.form-control {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.feed-card {
  width: 100%;
  color: #fff;
  background: #000;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  cursor: pointer;
  @include proportional(50%);
  .feed-card__inner {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 40%;
  }
  .feed-card__btn--icon {
    color: #fff;
    &:hover {
      color: #eee;
    }
    &:focus {
      outline: none;
    }
    &.feed-card__btn--preview {
      margin-top: -0.1em;
    }
  }
  .feed-card__top {
    position: relative;
    width: 100%;
    @include proportional(60%);
    .feed-card__controls {
      background: linear-gradient(#000000cc, #00000000);
      .feed-card__edit {
        cursor: pointer;
      }
    }
    .feed-card__title {
      background: linear-gradient(#00000000, #000000cc);
      position: absolute;
      left: 0;
      bottom: 0;
      h4 {
        font-weight: normal;
        font-size: 1.3em;
      }
    }
  }
  .feed-card__bottom {
    background: #000000cc;
    position: relative;
    @include proportional(16.67%);
    .feed-card__description {
      p {
        line-height: 1.5em;
      }
      font-size: 0.9em;
      max-height: 4em;
      overflow-y: hidden;
      transition: height 0.3s, max-height 0.5s;
    }
    .feed-card__expander {
      position: absolute;
      left: 0;
      bottom: 0;
      background: linear-gradient(#00000000, #000000ff);
      .feed-card__expand {
        background: #000;
        cursor: pointer;
      }
    }
  }

  &.feed-card--expanded {
    .feed-card__bottom {
      .feed-card__description {
        overflow-y: scroll;
        max-height: 30em;
      }
      .feed-card__expander {
        background: none;
      }
    }
  }
  .feed-card__link {
    &.feed-card__link--entity {
      font-size: 0.9em;
      font-style: italic;
    }
  }
  &.feed-card--light {
    color: #000;
    background-color: #fff;
    background-size: contain;
    .feed-card__btn--icon {
      color: #000;
      &:hover {
        color: #333;
      }
    }
    .feed-card__top {
      .feed-card__controls {
        background: linear-gradient(#ffffffcc, #ffffff00);
      }
      .feed-card__title {
        background: linear-gradient(#ffffff00, #ffffffcc);
      }
    }
    .feed-card__bottom {
      background: #ffffffcc;
      .feed-card__expander {
        background: linear-gradient(#ffffff00, #ffffffff);
        .feed-card__expand {
          background: #fff;
        }
      }
    }
  }
}

.feed {
  .feed__grid {
    min-width: 50%;
  }
  .feed__row {
    margin-left: -22.5px;
    margin-right: -22.5px;
  }
  h2 {
    &.feed__heading {
      font-size: 1.8rem;
    }
  }
  h3 {
    &.feed__heading {
      font-size: 1.4rem;
    }
  }
}

.search-form {
  &.border-secondary {
    border-color: #ccc !important;
  }
  .search-form__input:focus {
    outline: none;
  }
}

.comment-button {
  .comment-button__count {
    font-size: 0.8em;
    padding: 0.1em 0.4em;
  }
}

.comment-post-form {
  .file-input--icon {
    cursor: pointer;
    font-size: 1.5em;
  }
  &.comment-post-form--reply {
    .file-input--icon {
      font-size: 1.2em;
    }
  }
}

.chat {
  font-size: 1em;
  h6 {
    font-size: 0.8em;
  }
  p {
    &.time {
      font-style: italic;
      font-size: 0.8em;
    }
  }
  .alert-dismissible {
    button {
      &.close {
        padding: 0 0.25rem;
      }
    }
  }
}

.chat-panel {
  .chat {
    overflow-y: scroll;
  }
  .form-check {
    font-size: 0.8em;
  }
  .form-label {
    .svg-icon {
      font-size: 2em;
    }
  }
}

.post {
  .post__content {
    .post__content__header {
      h5 {
        font-size: 1em;
        a {
          color: #343a40;
        }
      }
    }
    .post__content__body {
      font-size: 0.9em;
    }
    .post__controls {
      color: #6c757d;
      font-size: 0.8em;
      .comment-button {
        .comment-button__count {
          padding-top: 0.05em;
        }
      }
    }
  }
  .post__image {
    max-width: 17rem;
    max-height: 20rem;
    @include proportional(100%);
  }
  &.post--private {
    background-color: #6c757d;
    color: #fff;
    .post__content {
      .post__content__header {
        h5 {
          a {
            color: #fff;
          }
        }
      }
      .post__time {
        color: #fff;
      }
    }
    button {
      color: #fff;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.thumbnail {
  width: 240px;
  height: 150px;
  box-sizing: border-box;
  &.thumbnail--avatar {
    width: 256px;
    height: 256px;
  }
  .thumbnail__delete {
    top: 0.2em;
    right: 0.2em;
  }
  .thumbnail__caption {
    bottom: 0;
    left: 0;
    &:focus {
      outline: none;
    }
  }
}

.avatar {
  background-position: center center;
  background-size: cover;
}

.background-image-cover {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-image-contain {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.black-grad-down {
  @include black-grad-down();
}

.black-grad-up {
  @include black-grad-up();
}

.image-block {
  @include proportional();
}

.graphic-block {
  .graphic-block__image {
    width: 80%;
    height: auto;
  }
}

.image-block__caption,
.graphic-block__caption {
  font-size: 1.2rem;
}

.row--full-width {
  margin-left: -15px;
  margin-right: -15px;
}

.tag-suggestions {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

.dropdown-toggle:after {
  display: none !important;
}

.dropdown-item {
  &:active {
    background-color: #fff;
  }
  &.dropdown-item--cart {
    width: 100vw;
  }
}

.dropdown-menu {
  &.show {
    max-width: 100vw;
  }
}

.feed-container {
  overflow-x: hidden;
  .feed-content {
    transition: margin 0.3s;
    &.feed-content {
      width: 200%;
      &.feed-content--comments {
        margin-left: -100%;
      }
    }
  }
}

.sortableHelper {
  z-index: 10000 !important;
  list-style-type: none;
}

.carousel-caption {
  left: 0;
  right: 0;
  bottom: 0;
  color: #212529;
  padding: 0;
  p {
    margin-bottom: 0;
  }
}

.carousel-indicators {
  margin-bottom: 0.3rem;
  li {
    background-color: #212529;
  }
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ccc' width='8' height='8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ccc' width='8' height='8'%3E%3Cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-item {
  img {
    max-width: 100%;
    max-height: 480px;
    width: auto;
    height: auto;
  }
}

.product__card {
  .carousel-item {
    img {
      max-height: 240px;
    }
  }
}

.product__price {
  sup {
    font-size: 57%;
  }
}

.sponsors__list {
  .sponsors__image {
    max-height: 100px;
  }
}

.draggable {
  cursor: pointer;
}

@media (min-width: 768px) {
  .col-md-9 {
    width: 75%;
  }
  .preview,
  .banner {
    .heading {
      h1 {
        font-size: 2.5rem;
      }
      h3 {
        font-size: 1.4rem;
      }
    }
  }

  .badge-md-large {
    font-size: 1em;
  }

  .btn-md-inline {
    display: inline-block;
    width: auto;
  }

  .banner {
    .banner__top {
      @include proportional(20%);
      .banner__controls {
        font-size: 1em;
      }
    }
  }

  .feed-container {
    overflow-x: visible;
    .feed-content {
      &.feed-content {
        width: 100%;
        &.feed-content--comments {
          margin-left: 0;
        }
      }
    }
  }
  .feed-card {
    .feed-card__top {
      @include proportional(60%);
      .feed-card__title {
        h4 {
          font-size: 1.5em;
        }
      }
    }
    .feed-card__bottom {
      @include proportional(12.5%);
      .feed-card__description {
        font-size: 1em;
        max-height: 3.4em;
      }
    }
  }

  .time-display {
    font-size: 0.85em;
    &.time-display--emphasised {
      font-size: 1.5em;
    }
  }

  .player {
    .player-comments {
      height: 100vh;
    }
    .player-footer {
      position: fixed;
      left: 0;
      bottom: 0;
      .footer__cta {
        font-size: 1em;
      }
    }
    .player-video {
      height: calc(100% - 65px);
    }
  }

  .dropdown-item {
    &.dropdown-item--cart {
      width: auto;
      min-width: 25vw;
    }
  }

  .post {
    .post__image {
      max-width: 19rem;
    }
  }

  .image-block {
    @include proportional(45%);
  }

  .graphic-block {
    .graphic-block__image {
      width: 65%;
    }
  }

  .image-block__caption,
  .graphic-block__caption {
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  .graphic-block {
    .graphic-block__image {
      width: 60%;
    }
  }
  .image-block__caption,
  .graphic-block__caption {
    font-size: 1.8rem;
  }
}

/* Buttons styles start */
button {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: 1.15;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:hover,
  &:focus {
    outline: none;
  }
  &.inline-link {
    color: #007bff;
    &:hover {
      border-bottom: 2px solid #007bff;
      text-decoration: none;
    }
  }
  &:hover {
    text-decoration: underline;
  }
}
/* Button styles end */

.accordion-button:not(.collapsed) {
  background-color: #f0f0f0;
  color: #212529;
}

.sales-report__header {
  .accordion-button {
    padding: 0;
    padding-bottom: 0.25rem;
    border-color: #fff;
    color: #6c757d;
    &:focus {
      box-shadow: none;
    }
    &:not(.collapsed) {
      background-color: #fff;
    }
  }
}

@for $i from 1 through 6 {
  $zIndexBackdrop: #{1000 + (5 * $i)};
  $zIndexContent: #{1000 + (5 * $i) + 2};
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role='dialog'][aria-modal='true']:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

.offcanvas {
  z-index: 1100 !important;
}
