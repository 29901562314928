.dynamic__content__carousel__container {
  .carousel-inner {
    height: 100%;
    .carousel-item {
      transition-duration: 2s;
    }
  }
  .carousel {
    z-index: 5;
  }
  .dynamic__content__carousel__content {
    z-index: 10;
  }
}
