.foyer-window{
    background: #000;
    color: #FFF;
    padding: 8px;
}

.foyer-header{
    .foyer-header__title{
      background: linear-gradient(#00000000, #000000DD);
    }
    .foyer-header__tabs{
      margin-left: -1rem;
      margin-right: -1rem;
    }
}
.foyer-header__navigation{
   background: linear-gradient(#000000FF, #00000000);
   z-index: 1000;
   top: 0;
}

.foyer__content{
    -webkit-columns: 5 180px;
    -moz-columns: 5 180px;
    columns: 5 180px;
    column-gap: 8px;
    .foyer__item{
        .lightbox-cell{
            background-position: center center;
            background-size: cover;
            h3{
                font-size: 1.4em;
                background: linear-gradient(#000000DD, #00000000);
            }
            h4{
                font-size: 1em;
                font-weight: normal;
                background: linear-gradient(#00000000, #000000DD);
                bottom: 0;
                left: 0;
            }
            .lightbox-cell__inner{
                transition: background 0.5s;
                .lightbox-cell__description{
                    transition: opacity 0.5s;
                    opacity: 0
                }
                &:hover{
                    .lightbox-cell__description{
                        opacity: 1;
                    }
                }
            }
        }
    }
}