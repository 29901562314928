@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,700;1,300;1,700&display=swap');

.theme__panorama {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300 !important;
  strong,
  bold,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  .fw-bold {
    font-weight: 700 !important;
  }
  .theme__panorama__control_strip {
    z-index: 99;
    .theme__panorama__menu_button {
      z-index: 100;
    }
    .theme__panorama__home_button img {
      width: 36px;
      height: auto;
    }
  }
  .theme__panorama__content {
    a {
      color: #fff !important;
    }
  }
  .theme__panorama__content_tiles {
    z-index: 20;
  }
}

.theme__panorama__menu__offcanvas {
  a.visibility_private {
    color: #aaa !important;
  }
}
