/**
 * Settings Window CSS
 */

.mainMenu{
    a, button{
        color: #777;
        font-size: 1.5rem;
        margin: 0.5rem 0;
    }
}

.breadcrumb{
    margin: 1rem 0;
    font-size: 0.9em;
    a{
        color: #FF8100;
        text-decoration: none;
    }
}