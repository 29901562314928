body,
html {
  background-color: #212529;
  padding: 0;
  margin: 0;
}

.popover {
  &.schedule {
    z-index: 1020;
  }
}

.schedule_cart {
  z-index: 1090;
}
