/*
 Only custom marker for summary/details
 For cross browser compatible styling hide Firefox's marker by setting summary { display: block }
 and Chrome and Safari's marker by setting ::-webkit-details-marker {display: none;}
*/
summary {
  display: block;
  cursor: pointer;
  outline: 0;
  &:-webkit-details-marker {
    display: none;
  }
}

.tree-nav__item {
  display: block;
  white-space: nowrap;
  color: #777;
  position: relative;
  &.is-expandable{
    &::before {
      border-left: 1px solid #CCC;
      content: "";
      height: 100%;
      left: 0.7rem;
      position: absolute;
      top: 2.4rem;
      height: calc(100% - 2rem);
    }
    & > .tree-nav__item-title {
      padding-left: 2rem;
      &::before {
        position: absolute;
        will-change: transform;
        transition: transform 300ms ease;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 1.1rem;
        content: "\f105";
        left: 0;
        display: inline-block;
        width: 1.6rem;
        text-align: center;
      }
    }
  }
  .tree-nav__item {
    margin-left: 1.6rem;
  }
}

.tree-nav__item.is-expandable[open] > .tree-nav__item-title::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transform: rotate(90deg);
}


.tree-nav__item-title {
  cursor: pointer;
  display: block;
  outline: 0;
  font-size: 1.2rem;
  line-height: 3rem;
  &::-webkit-details-marker {
    display: none;
  }
  &::before{
    content: " ";
    display: inline-block;
    width: 2rem;
  }
  & > svg {
    font-size: 0.5em;
    left: 0.5rem;
    top: 1.2rem;
    position: absolute;
  }
}